import logo from 'assets/logo/DOTgod.svg';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Image, Row } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { LoginForm } from 'services/users';
import Swal from 'sweetalert2';
import { AuthVerify } from 'utils/auth.utils';
const Signin = () => {
    const navigate = useNavigate()
    const verify = AuthVerify();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleCheckboxChange = () => {
        setRememberMe(!rememberMe);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        try {
            const response = await LoginForm({
                email,
                password
            });

            const user = response?.data?.data;
            console.log("Response", response.data.data);
            localStorage.setItem('user', JSON.stringify(user));

            Swal.fire({
                icon: 'success',
                title: 'Login geslaagd',
                text: 'Niet leuker, wel gemakkelijker',
            });

            navigate('/dashboard');
        } catch (err) {
            console.error('Error during login:', err);
            const errorMessage = err?.response?.data?.error || 'An unexpected error occurred.';

            Swal.fire({
                icon: 'error',
                title: 'Login Failed',
                text: errorMessage,
            });

            setError(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (verify) {
            navigate(`/dashboard`)
        }
        // eslint-disable-next-line 
    }, [navigate, verify]);
    return (
        <div style={{ background: "var(--color1)" }} className=' d-flex justify-content-center align-items-center min-vh-100'>
            <Row className='w-100'>
                <Col lg={4} md={6} sm={8} xs={12} className='mx-auto'>
                    <div style={{ boxShadow: " 0px 5px 14px 0px #0000000D" }} className='bg-white rounded-4   p-xl-5 p-sm-4 p-3'>
                        <div className="d-flex justify-content-center">
                            <Image src={logo} alt="DOTgod" />
                        </div>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formEmail" className='mb-3'>
                                <Form.Label className='text-custom'>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Voer uw e-mailadres in"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formPassword" className='mb-3 position-relative'>
                                <Form.Label className='text-custom'>Wachtwoord</Form.Label>
                                <Form.Control
                                    type={passwordVisible ? "text" : "password"}
                                    placeholder="Voer uw wachtwoord in"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <div
                                    className="position-absolute top-50 end-0 mx-2 pt-1 pe-2 bg-white"
                                    style={{ cursor: 'pointer' }}
                                    onClick={togglePasswordVisibility}
                                >
                                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </Form.Group>
                            <Form.Group controlId="formRememberMe" className='mb-3'>
                                <Form.Check
                                    type="switch"
                                    id="flexSwitchCheckChecked"
                                    label="Onthouden"
                                    checked={rememberMe}
                                    onChange={handleCheckboxChange}
                                    className='custom-switch text-custom'
                                />
                            </Form.Group>
                            <Button
                                type="submit"
                                className='w-100 py-2'
                                style={{ background: "var(--bg-ltr)", borderColor: "var(--primary-color)" }}
                                disabled={loading}
                            >
                                <small>{loading ? 'Aanmelden...' : 'Inloggen'}</small>
                            </Button>
                            {/* <div className='text-center text-secondary mt-3'>
                                Don’t have an account?
                                <Link to="/signup" className='text-decoration-none text-custom px-1'>
                                    Sign up
                                </Link>
                            </div> */}
                            {/* <div className='text-center text-secondary mt-3'>
                                <Link to="/forgot-password" className='text-decoration-none text-custom px-1'>
                                    Forgot Password?
                                </Link>
                            </div> */}

                        </Form>
                    </div>
                </Col>
            </Row>
        </div >
    );
};

export default Signin;
